.archk-spin {

    -webkit-animation: spin1 2s infinite linear;
    -moz-animation: spin1 2s infinite linear;
    -o-animation: spin1 2s infinite linear;
    -ms-animation: spin1 2s infinite linear;
    animation: spin1 2s infinite linear;

    @-webkit-keyframes spin1 {
        0% { -webkit-transform: rotate(0deg);}
        100% { -webkit-transform: rotate(360deg);}
    }
    @-moz-keyframes spin1 {
        0% { -moz-transform: rotate(0deg);}
        100% { -moz-transform: rotate(360deg);}
    }
    @-o-keyframes spin1 {
        0% { -o-transform: rotate(0deg);}
        100% { -o-transform: rotate(360deg);}
    }
    @-ms-keyframes spin1 {
        0% { -ms-transform: rotate(0deg);}
        100% { -ms-transform: rotate(360deg);}
    }
    @keyframes spin1 {
        0% { transform: rotate(0deg);}
        100% { transform: rotate(360deg);}
    }

}

.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.email-content body {
    background: transparent;
    min-height: 0;
}


.system-loader {

    .container {
        height: 100vh;
        width: 100vw;
        font-family: Helvetica;
        background: white;
        z-index: 999;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .loader {
        height: 20px;
        width: 250px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .loader--dot {
        animation-name: loader;
        animation-timing-function: ease-in-out;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: black;
        position: absolute;
        border: 2px solid white;
    }
    .loader--dot:first-child {
        background-color: lighten($green, 10%);
        animation-delay: 0.5s;
    }
    .loader--dot:nth-child(2) {
        background-color: lighten($green, 5%);

        animation-delay: 0.4s;
    }
    .loader--dot:nth-child(3) {
        background-color: lighten($green, 0%);
        animation-delay: 0.3s;
    }
    .loader--dot:nth-child(4) {
        background-color: lighten($teal, 0%);
        animation-delay: 0.2s;
    }
    .loader--dot:nth-child(5) {
        background-color: lighten($teal, 5%);
        animation-delay: 0.1s;
    }
    .loader--dot:nth-child(6) {
        background-color: lighten($teal, 10%);
        animation-delay: 0s;
    }
    .loader--text {
        position: absolute;
        top: 200%;
        left: 0;
        right: 0;
        width: 4rem;
        margin: auto;
    }
    .loader--text:after {
        content: "Loading";
        font-weight: bold;
        animation-name: loading-text;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    @keyframes loader {
        15% {
            transform: translateX(0);
        }
        45% {
            transform: translateX(230px);
        }
        65% {
            transform: translateX(230px);
        }
        95% {
            transform: translateX(0);
        }
    }
    @keyframes loading-text {
        0% {
            content: "Loading";
        }
        25% {
            content: "Loading.";
        }
        50% {
            content: "Loading..";
        }
        75% {
            content: "Loading...";
        }
    }

}

.tooltip {
  z-index: 100000 !important;
}

.raise-1 {
  z-index: 1;
}

.raise-2 {
  z-index: 2;
}

.raise-3 {
  z-index: 3;
}

.raise-4 {
  z-index: 4;
}

.lower-1 {
  z-index: -1;
}

.lower-1 {
  z-index: -2;
}

.lower-3 {
  z-index: -3;
}

.lower-4 {
  z-index: -4;
}

.color-circle {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    display: inline-block;
    margin-left: $spacer * .5;
    margin-right: $spacer * .5;
}

.avatar-image {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 1px rgba(255,255,255,.3);
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.resolved-alert {
    .item-wrapper {
        opacity: .85;
        border: solid 3px $green;
    }
}

$activeColor: #0274be;
$borderRadius: 2rem;

body {
    background: $activeColor
}

.border-top-left-radius {
    border-top-left-radius: $borderRadius / 2;
}
.border-top-right-radius {
    border-top-right-radius: $borderRadius / 2;
}
.border-bottom-left-radius {
    border-bottom-left-radius: $borderRadius / 2;
}
.border-bottom-right-radius {
    border-bottom-right-radius: $borderRadius / 2;
}

.archk-main {
    position: fixed;
    overflow: auto;
    // top: 75px
    // top: 55px;
    top: 0px;
    border-top-left-radius: $borderRadius;

    right: 0;
    bottom: 0;
    left: 250px;


    background: $body-bg;
    border-bottom-left-radius: $borderRadius;
    padding: 20px 40px;

    .alert {
        border-radius: $borderRadius / 2;
    }

    .card {
        border-radius: $borderRadius / 2;

        .card-header {
            border-top-left-radius: $borderRadius / 2;
            border-top-right-radius: $borderRadius / 2;
        }
        .card-footer {
            border-bottom-left-radius: $borderRadius / 2;
            border-bottom-right-radius: $borderRadius / 2;
        }

    }

}

.archk-header {
  display: none;
}


.archk-sidebar-large {

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    overflow: auto;
    width: 250px;
    // background-color: $activeColor;

    .archk-sidebar-logo-wrapper {
        // background-color: $white;
        padding: 20px 40px;
        // border-bottom: solid 1px $white;
    }

    ul {
        padding-left: 0;
       

        li {

            margin-left: 20px;

            list-style: none;
            background: $body-bg;
            color: $white;
            font-weight: bold;

            i {
                color: $white;
                margin-right: 24px;
                width: 20px;
                font-size: 20px;
            }
          

            .archk-sidebar-large-item-wrapper {
                background-color: $activeColor;
                padding: 8px;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 20px;
            }

            &.archk-sidebar-large-active {
                border-top-left-radius: $borderRadius;
                border-bottom-left-radius: $borderRadius;

                color: $activeColor;
                i {
                    color: $activeColor;
                }

                .archk-sidebar-large-item-wrapper {
                    background-color: $body-bg;
                    border-top-left-radius: $borderRadius;
                    border-bottom-left-radius: $borderRadius;
                    
                }
            }
            &.archk-sidebar-large-active-before {

                .archk-sidebar-large-item-wrapper {
                    border-bottom-right-radius: $borderRadius;
                }
            }
            &.archk-sidebar-large-active-after {

                .archk-sidebar-large-item-wrapper {
                    border-top-right-radius: $borderRadius;
                }
            }

        }

    }

}

@media(max-width: 1000px) {
    .archk-main {

        .archk-doc-col-left {
            width: 100% !important;
        }

    }
}

@media(max-width: 750px) {

    body {
        background: $body-bg
    }

    .archk-header {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 20px;
        z-index: 35;

        background: $activeColor;
    
        .archk-sidebar-open {
            position: absolute;
            top: 20px;
            right: 20px;
            color: $white;
            font-size: 26px;
            cursor: pointer;
        }
    
    }
    

    .archk-sidebar-large {

        display: none;

        .archk-sidebar-blackout {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 250px;
            background: rgba(0,0,0,.5);
            z-index: 2;
        }

        &.open {
            display: block;

            z-index: 100;
            background: $activeColor;

            -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
            box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);

            ul {
                
                li {
                    &.archk-sidebar-large-active-before {

                        .archk-sidebar-large-item-wrapper {
                            border-bottom-right-radius: 0;
                        }
                    }
                    &.archk-sidebar-large-active-after {
        
                        .archk-sidebar-large-item-wrapper {
                            border-top-right-radius: 0;
                        }
                    }
                }

            }

        }

    }

    .archk-main {
        position: static;
        padding: 20px 20px;
        margin-top: 70px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

}
